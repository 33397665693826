import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TreatmentsStoreEffects } from './effects';
import { treatmentsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('treatments', treatmentsReducer),
    EffectsModule.forFeature([TreatmentsStoreEffects]),
  ],
})
export class TreatmentsStoreModule {}
