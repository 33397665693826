import { Treatment, TreatmentCommon } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<Treatment> = createEntityAdapter<
  Treatment
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<Treatment> {
  isLoading?: boolean;
  error?: any;
  treatmentsbyRateplan: any;
  treatmentDetails: Treatment;
  treatmentsCommon: TreatmentCommon[];
  treatmentsbyAccommodationsRateplans: {
    [accommodation_id: number]: {
      [rateplan_id: number]: { id: number; name: string }[];
    };
  };
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  treatmentsCommon: null,
  treatmentsbyRateplan: null,
  treatmentsbyAccommodationsRateplans: null,
  treatmentDetails: null,
});
