import { Treatment, TreatmentCommon } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getTreatmentDetails = (state: State): Treatment =>
  state.treatmentDetails;

export const getTreatmentsCommon = (state: State): TreatmentCommon[] =>
  state.treatmentsCommon;

export const getTreatmentsRateplan = (state: State) =>
  state.treatmentsbyRateplan;

export const selectTreatmentState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('treatments');

export const selectAllTreatmentsItems: (
  state: object,
) => Treatment[] = featureAdapter.getSelectors(selectTreatmentState).selectAll;

export const selectTreatmentById = (id: string) =>
  createSelector(selectAllTreatmentsItems, (treatments: Treatment[]) => {
    if (treatments) {
      return treatments.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectTreatmentsError: MemoizedSelector<
  object,
  any
> = createSelector(selectTreatmentState, getError);

export const selectTreatmentsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectTreatmentState, getIsLoading);

export const selectTreatmentDetails: MemoizedSelector<
  object,
  Treatment
> = createSelector(selectTreatmentState, getTreatmentDetails);

export const selectTreatmentsCommon: MemoizedSelector<
  object,
  TreatmentCommon[]
> = createSelector(selectTreatmentState, getTreatmentsCommon);

export const selectTreatmentsbyRateplan: MemoizedSelector<
  object,
  any
> = createSelector(selectTreatmentState, getTreatmentsRateplan);

export const selectTreatmentsByAccommodationsRateplans: MemoizedSelector<
  object,
  {
    [accommodation_id: number]: {
      [rateplan_id: number]: { id: number; name: string }[];
    };
  }
> = createSelector(
  selectTreatmentState,
  (state: State) => state.treatmentsbyAccommodationsRateplans,
);
