import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TreatmentsService } from '@app/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';
import * as fromActions from './actions';

@Injectable()
export class TreatmentsStoreEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadRequest),
      switchMap(({ propertyId }) =>
        this.dataService.load(propertyId).pipe(
          map(({ data }: IResponseSuccess) =>
            featureActions.loadSuccess({
              items: data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(featureActions.loadFailure({ error }));
          }),
        ),
      ),
    ),
  );

  loadCommon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadCommonRequest),
      switchMap(({ propertyIds }) =>
        this.dataService.loadCommon(propertyIds).pipe(
          map(({ data }: IResponseSuccess) =>
            featureActions.loadCommonSuccess({
              items: data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(featureActions.loadCommonFailure({ error }));
          }),
        ),
      ),
    ),
  );

  loadTreatmentDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadTreatmentDetailRequest),
      switchMap(({ property_id, property_treatment_id }) =>
        this.dataService
          .loadTreatmentDetails(property_id, property_treatment_id)
          .pipe(
            map(({ data }: IResponseSuccess) =>
              featureActions.loadTreatmentDetailSuccess({
                items: { ...data },
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(featureActions.loadTreatmentDetailFailure({ error }));
            }),
          ),
      ),
    ),
  );

  loadByRateplans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadByRateplanRequest),
      switchMap(({ accommodationId, rateplanId }) =>
        this.dataService.loadByRateplan(accommodationId, rateplanId).pipe(
          map(({ data }: IResponseSuccess) =>
            featureActions.loadByRateplanSuccess({
              items: data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(featureActions.loadByRateplanFailure({ error }));
          }),
        ),
      ),
    ),
  );

  loadByAccommodationsRateplans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadByAccommodationsRateplansRequest),
      switchMap(({ payload, withTrashed }) =>
        this.dataService
          .loadTreatmentsAccommodationRateplan(payload, withTrashed)
          .pipe(
            map((response) => {
              return featureActions.loadByAccommodationsRateplansSuccess({
                response,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                featureActions.loadByAccommodationsRateplansFailure({ error }),
              );
            }),
          ),
      ),
    ),
  );

  attach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.attachRequest),
      switchMap(({ data }) =>
        this.dataService.attach(data).pipe(
          map((response: IResponseSuccess) => {
            const warningMessages: string[] = get(
              response,
              'meta.warnings',
              [],
            );

            if (warningMessages.length) {
              warningMessages.forEach((message) => {
                if (message) {
                  this.notifications.push({
                    title: this.translate.instant('warning'),
                    content: message,
                    type: 'warning',
                  });
                }
              });
            }

            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('treatment'),
              }),
              type: 'success',
            });
            this.router.navigate(['/configuration/treatments']);
            return fromActions.attachSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.attachFailure(error));
          }),
        ),
      ),
    ),
  );

  updateTreatmentAttacched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTreatmentAttachedRequest),
      switchMap(({ data }) =>
        this.dataService.updateTreatmentAttacched(data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('treatment'),
              }),
              type: 'success',
            });
            this.router.navigate(['/configuration/treatments']);
            return fromActions.updateTreatmentAttachedSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateTreatmentAttachedFailure(error));
          }),
        ),
      ),
    ),
  );

  detach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.detachRequest),
      switchMap(({ propertyId, treatmentId }) =>
        this.dataService.detach(propertyId, treatmentId).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant('treatment'),
              }),
              type: 'success',
            });
            return fromActions.detachSuccess({ treatmentId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.detachFailure(error));
          }),
        ),
      ),
    ),
  );

  setRatesTreatments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setRatesTreatmentsRequest),
      switchMap(({ data }) =>
        this.dataService.setRatesTreatments(data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('treatment'),
              }),
              type: 'success',
            });
            return fromActions.setRatesTreatmentsSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setRatesTreatmentsFailure(error));
          }),
        ),
      ),
    ),
  );

  constructor(
    private dataService: TreatmentsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}
}
