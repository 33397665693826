import { createAction, props } from '@ngrx/store';

import {
  SetRatesTreatmentsRequest,
  Treatment,
  TreatmentCommon,
  TreatmentEditRequest,
  TreatmentNewRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Treatments] Load Request',
  props<{ propertyId: number }>(),
);

export const loadSuccess = createAction(
  '[Treatments] Load Success',
  props<{
    items: Treatment[];
  }>(),
);

export const loadFailure = createAction(
  '[Treatments] Load Failure',
  props<{ error: any }>(),
);

export const loadCommonRequest = createAction(
  '[Treatments] Load Common Request',
  props<{ propertyIds: number[] }>(),
);

export const loadCommonSuccess = createAction(
  '[Treatments] Load Common Success',
  props<{
    items: TreatmentCommon[];
  }>(),
);

export const loadCommonFailure = createAction(
  '[Treatments] Load Common Failure',
  props<{ error: any }>(),
);

export const loadTreatmentDetailRequest = createAction(
  '[Treatments] Load Treatment Details Request',
  props<{ property_id: number; property_treatment_id: number }>(),
);

export const loadTreatmentDetailSuccess = createAction(
  '[Treatments] Load Treatment Details Success',
  props<{
    items: Treatment;
  }>(),
);

export const loadTreatmentDetailFailure = createAction(
  '[Treatments] Load Treatment Details Failure',
  props<{ error: any }>(),
);

export const loadByRateplanRequest = createAction(
  '[Treatments] Load By Rateplan Request',
  props<{ accommodationId: number; rateplanId: number }>(),
);

export const loadByRateplanSuccess = createAction(
  '[Treatments] Load By Rateplan Success',
  props<{
    items: any[];
  }>(),
);

export const loadByRateplanFailure = createAction(
  '[Treatments] Load By Rateplan Failure',
  props<{ error: any }>(),
);

/**
 * @description crea un nodo sullo store che contiene la mappa
 * così composta: accommodation_id => rateplan_id => treatments
 */
export const loadByAccommodationsRateplansRequest = createAction(
  '[Treatments] Load By Accommodations Rateplans Request',
  props<{
    payload: Array<{ accommodationId: number; rateplanId: number }>;
    withTrashed?: boolean;
  }>(),
);
export const loadByAccommodationsRateplansSuccess = createAction(
  '[Treatments] Load By Accommodations Rateplans Success',
  props<{
    response: {
      [accommodation_id: number]: {
        [rateplan_id: number]: { id: number; name: string }[];
      };
    };
  }>(),
);
export const loadByAccommodationsRateplansFailure = createAction(
  '[Treatments] Load By Accommodations Rateplans Failure',
  props<{ error: any }>(),
);

export const attachRequest = createAction(
  '[Treatments] Attach Request',
  props<{ data: TreatmentNewRequest }>(),
);

export const attachSuccess = createAction('[Treatments] Attach Success');

export const attachFailure = createAction(
  '[Treatments] Attach Failure',
  props<{ error: any }>(),
);

export const updateTreatmentAttachedRequest = createAction(
  '[Treatments] Update Treatment Attached Request',
  props<{ data: TreatmentEditRequest }>(),
);

export const updateTreatmentAttachedSuccess = createAction(
  '[Treatments] Update Treatment Attached Success',
);

export const updateTreatmentAttachedFailure = createAction(
  '[Treatments] Update Treatment Attached Failure',
  props<{ error: any }>(),
);

export const detachRequest = createAction(
  '[Treatments] Detach Request',
  props<{ propertyId: number; treatmentId: number }>(),
);

export const detachSuccess = createAction(
  '[Treatments] Detach Success',
  props<{ treatmentId: number }>(),
);

export const detachFailure = createAction(
  '[Treatments] Detach Failure',
  props<{ error: any }>(),
);

export const setRatesTreatmentsRequest = createAction(
  '[Treatments] Set Supplements Request',
  props<{ data: SetRatesTreatmentsRequest }>(),
);

export const setRatesTreatmentsSuccess = createAction(
  '[Treatments] Set Supplements Success',
);

export const setRatesTreatmentsFailure = createAction(
  '[Treatments] Set Supplements Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Treatments] Reset State');
