import { Action, createReducer, on } from '@ngrx/store';
import { get } from 'lodash';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadCommonRequest, (state) => ({
    ...state,
    treatmentsCommon: null,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadCommonSuccess, (state, { items }) => ({
    ...state,
    treatmentsCommon: items,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadCommonFailure, (state, { error }) => ({
    ...state,
    treatmentsCommon: null,
    isLoading: false,
    error,
  })),
  on(fromActions.loadTreatmentDetailRequest, (state) => ({
    ...state,
    treatmentDetails: null,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadTreatmentDetailSuccess, (state, { items }) => ({
    ...state,
    treatmentDetails: items,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadTreatmentDetailFailure, (state, { error }) => ({
    ...state,
    treatmentDetails: null,
    isLoading: false,
    error,
  })),
  on(fromActions.loadByRateplanRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadByRateplanSuccess, (state, { items }) => ({
    ...state,
    isLoading: false,
    error: null,
    treatmentsbyRateplan: get(
      items[0],
      'accommodation_rateplans.[0].treatments',
      [],
    ).map((treatment) => treatment.treatment),
  })),
  on(fromActions.loadByRateplanFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadByAccommodationsRateplansRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadByAccommodationsRateplansSuccess,
    (state, { response }) => ({
      ...state,
      isLoading: false,
      error: null,
      treatmentsbyAccommodationsRateplans: response,
    }),
  ),
  on(fromActions.loadByAccommodationsRateplansFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.detachRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.detachSuccess, (state, { treatmentId }) =>
    fromState.featureAdapter.removeOne(treatmentId, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.detachFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.attachRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.attachSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.attachFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateTreatmentAttachedRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateTreatmentAttachedSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.updateTreatmentAttachedFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.setRatesTreatmentsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.setRatesTreatmentsSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.setRatesTreatmentsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function treatmentsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
